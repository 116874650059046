<template>
  <div>
    <ConfirmDialog />
    <CameraDialog
      v-if="cameraDialogStore.camera && cameraDialogStore.dialog"
      :camera="cameraDialogStore.camera"
      :inline="cameraDialogStore.isInline"
      @close="goBack"
    />
    <ProjectDialog
      v-if="projectStore.selectedProjectExid && projectStore.dialog"
      :project-exid="projectStore.selectedProjectExid"
      :show-dialog="projectStore.dialog"
      @close="goBack"
    />
    <UserDialog v-if="userStore.dialog" @close="goBack" />
    <RouterSummaryDialog
      v-if="routerStore.dialog && routerStore.selectedRouter"
      @close="goBack"
    />
  </div>
</template>

<script>
import ConfirmDialog from "@evercam/shared/components/ConfirmDialog"
import ProjectDialog from "@/components/projects/ProjectDialog"
import CameraDialog from "@/components/cameraDialog"
import UserDialog from "@/components/users/UserDialog"
import RouterSummaryDialog from "@/components/routers/RouterSummaryDialog"

import { mapStores } from "pinia"
import { useCameraDialogStore } from "@/stores/cameraDialog"
import { useProjectStore } from "@/stores/projects"
import { useUserStore } from "@/stores/users"
import { useRouterStore } from "@/stores/router"
import { useGlobalDialogStore } from "@/stores/globalDialogs"

import { camelizeKeys } from "humps"

export default {
  name: "TheGlobalDialogs",
  components: {
    ConfirmDialog,
    ProjectDialog,
    CameraDialog,
    UserDialog,
    RouterSummaryDialog,
  },
  computed: {
    ...mapStores(
      useCameraDialogStore,
      useProjectStore,
      useUserStore,
      useRouterStore,
      useGlobalDialogStore
    ),
  },
  created() {
    this.initDialogs()
    this.$addEventListener("hashchange", this.initDialogs)
    this.$root.$on("sidebar-navigation", () => {
      this.globalDialogsStore.closeDialogs()
    })
    this.$router.afterEach(this.initDialogs)
  },
  methods: {
    initDialogs() {
      const {
        projectDialog,
        projectExid,
        cameraDialog,
        cameraExid,
        userDialog,
        userId,
        routerDialog,
        serialNumber,
      } = camelizeKeys(this.getSubQueryParams())

      if (userDialog && userId) {
        this.userStore.openDialog(userId, false)
      } else if (projectDialog && projectExid) {
        this.projectStore.openDialog(projectExid, false)
      } else if (cameraDialog && cameraExid) {
        this.cameraDialogStore.openDialog({ camera: cameraExid }, false)
      } else if (serialNumber && routerDialog) {
        this.routerStore.openDialog(serialNumber, false)
      } else {
        this.globalDialogsStore.closeDialogs()
      }
    },
    goBack() {
      const startLocation = this.$router.history._startLocation
      const currentPath = this.$router.history.getCurrentLocation()
      if (startLocation === currentPath) {
        this.globalDialogsStore.closeDialogs()
      } else {
        this.$router.go(-1)
      }
    },
  },
}
</script>
